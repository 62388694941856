import React from 'react'
import FacebookLogo from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/Facebook'
import GonationLogo from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/GoNationMan'
import InstagramLogo from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/Instagram'
import LinkedinLogo from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/Linkedin'
import PinterestLogo from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/Pinterest'
import Twitter from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/Twitter'
import YouTube from '../../../../../../gatsby-theme-minimal/src/components/General/Icons/YouTube'

import { Flex, Link, Image } from 'theme-ui'

export default function SocialIconsV1({ links, slug, variantName }) {
  const variant = variantName ? variantName : 'socialIconsV1'
  return (
    <Flex variant={`${variant}.socialIconsContainer`} className="socialContainer socialIconsContainer">
      {links.facebook ? (
        <Link href={`${links.facebook}`} target="_blank" rel="noreferrer" className="socialLink facebook">
          {/* <FacebookLogo /> */}
          <Image src="https://res.cloudinary.com/gonation/image/upload/sites/westover-pizza/facebook.png" />
        </Link>
      ) : (
        ''
      )}
      {links.instagram ? (
        <Link href={`${links.instagram}`} target="_blank" rel="noreferrer" className="socialLink instagram">
          {/* <InstagramLogo /> */}
          <Image src="https://res.cloudinary.com/gonation/image/upload/v1640705993/sites/westover-pizza/instagram.png" />
        </Link>
      ) : (
        ''
      )}
      {links.linkedin ? (
        <Link href={`${links.linkedin}`} target="_blank" rel="noreferrer" className="socialLink linkedin">
          <LinkedinLogo />
        </Link>
      ) : (
        ''
      )}
      {links.youtube ? (
        <Link href={`${links.youtube}`} target="_blank" rel="noreferrer" className="socialLink youtube">
          <YouTube />
        </Link>
      ) : (
        ''
      )}
      {links.twitter ? (
        <Link href={`${links.twitter}`} target="_blank" rel="noreferrer" className="socialLink twitter">
          {/* <Twitter /> */}
          <Image src="https://res.cloudinary.com/gonation/image/upload/sites/westover-pizza/twitter.png" />
        </Link>
      ) : (
        ''
      )}
      {links.pinterest ? (
        <Link href={`${links.pinterest}`} target="_blank" rel="noreferrer" className="socialLink pinterest">
          <PinterestLogo />
        </Link>
      ) : (
        ''
      )}

      {slug ? (
        <Link
          href={`https://www.gonation.com/place/${slug}/pulse`}
          target="_blank"
          rel="noreferrer"
          className="socialLink gonation"
        >
          <GonationLogo />
        </Link>
      ) : (
        ''
      )}
    </Flex>
  )
}
